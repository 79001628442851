import { onLoad } from "../../common/js/utils/util";
import { initSuggestions } from "../../common/js/suggestions";
import { formValidation } from "/assets-es6/yourdsposal/js/compliance/form-val";
import { initCore } from "./core";

onLoad(()=>{
    initCore();
    initSuggestions($("#compliance-form").data("suggestions"));
    const url = new URL(window.location.href);
    formValidation('#compliance-form', url.protocol + url.host + $('#compliance-form').data("compliancesearch"));
});
