import "jquery-validation";

export function formValidation(formSelector = "#compliance-form", redirectUrl) {
    // console.log("form-val formValidation");

    $(formSelector).validate({
        errorClass: "is-invalid",
        validClass: "is-valid",
        submitHandler: function (form, e) {
            e.preventDefault();

            var $btn = $(this.submitButton);
            $btn.addClass("running").attr("disabled", true);

            var url = new URL(redirectUrl);

            if ($("#keyword").val())
                url.searchParams.set("keyword", $("#keyword").val());
            else
                url.searchParams.delete("keyword");

            if ($("#type").val())
                url.searchParams.set("type", $("#type").val());
            else
                url.searchParams.delete("type");

            window.location.href = url;
            return false;
        }
    });
}